export default function StylistInfo({ post }) {
  const { isAdmin } = useCurrent()
  const { user: author, stylist } = post
  const renderSubTitle = () => {
    if (isAdmin) return <C.FromNow time={ post.createdAt } />
    if (stylist.studio) return stylist.studio.name
    return stylist.profession.name
  }

  const renderBadge = () => {
    if (!stylist.isGuarantee) return
    return <C.Image src={ cdn('images/badge/guarantee64.png') } className='w-5 h-5' alt=''/>
  }

  return (
    <C.Link href={ h.path.stylist({ id: author.id }) } preventDefault noUnderline>
      <div className='px-5 py-2 flex space-x-3 items-center border-b'
        onClick={ () => h.path.go.stylist({ id: author.id }) }>
        <C.Avatar src={ author.avatar }/>
        <div className='flex-1'>
          <div className='flex items-center space-x-1'>
            <div className='text-sm font-medium line-clamp-1'>{ author.name }</div>
            { renderBadge() }
          </div>
          <div className='text-xs text-gray-500'>{ renderSubTitle() }</div>
        </div>
      </div>
    </C.Link>
  )
}
