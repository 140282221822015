import StylistInfo from './stylist-info'
import Photos from './photos'
import Description from './description'
import Buttons from './buttons'

export default function Post({ post, closePanel }) {
  const { state, mutate } = useStatic({
    post: null,
    photos: [],
    largePhotos: [],
    width: 0,
    height: 0
  })

  const reloadPost = () => {
    const post = state.post
    state.photos = post.photos.map(photo => h.path.postPhoto({ id: photo.id, size: 'normal' }))
    state.largePhotos = post.photos.map(photo => h.path.postPhoto({ id: photo.id, size: 'large' }))
    state.beforePhoto = state.post.postBeforePhoto?.photo?.large?.url
    if (state.beforePhoto) {
      state.photos.splice(1, 0, state.post.postBeforePhoto.photo.normal.url)
      state.largePhotos.splice(1, 0, state.post.postBeforePhoto.photo.large.url)
    }
    const photo = post.photos[0]
    state.width = photo.width
    state.height = photo.height
    mutate()
  }

  useEffect(() => {
    state.post = post
    if (!post) return mutate()
    reloadPost()
  }, [post])

  useEffect(() => {
    window.addEventListener('resize', mutate)
    return () => window.removeEventListener('resize', mutate)
  }, [])

  const splitPhotoStyle = () => {
    const { width, height } = state
    if (!width) return {
      width: 15 + 'rem',
      height: 20 + 'rem'
    }
    const { clientHeight, clientWidth } = document.documentElement
    const maxWidth = clientWidth * 0.65
    const maxHeight = Math.min(clientHeight * 0.95, 640)
    const isWider = width / maxWidth > height / maxHeight
    if (isWider) {
      const base = Math.min(width, maxWidth)
      return {
        width: base + 'px',
        height: height * base / width + 'px'
      }
    }
    const base = Math.min(height, maxHeight)
    return {
      width: width * base / height + 'px',
      height: base + 'px'
    }
  }

  const photoSplitStyle = splitPhotoStyle()

  if (!state.post) return null

  return (
    <div className='flex flex-col shadow-md'>
      <div className='shrink-0 sm:hidden'>
        <StylistInfo post={ state.post } />
      </div>
      <div className='flex-1 overflow-scroll sm:flex'>
        <Photos state={ state } photoSplitStyle={ photoSplitStyle }/>
        <div className='sm:hidden'>
          <Description post={ state.post } />
        </div>
        <div className='hidden sm:flex w-[20rem] flex-col'
          style={ { maxHeight: photoSplitStyle.height } }
        >
          <div className='shrink-0'>
            <StylistInfo post={ state.post } />
          </div>
          <div className='flex-1 overflow-scroll'>
            <Description post={ state.post } />
          </div>
          <div className='shrink-0'>
            <div className='relative'><C.Blur/></div>
            <Buttons state={ state } mutate={ mutate } reloadPost={ reloadPost } closePanel={ closePanel }/>
          </div>
        </div>
      </div>
      <div className='shrink-0 sm:hidden'>
        <div className='relative'><C.Blur/></div>
        <Buttons state={ state } mutate={ mutate } reloadPost={ reloadPost } closePanel={ closePanel }/>
      </div>
    </div>
  )

}
