import StarRateIcon from '@mui/icons-material/StarRate'

export default function Description({ post }) {
  const t = useI18n()

  return (
    <div className='px-4 pb-8'>
      <div className='text-sm py-4' { ...post.description.rawHtml() }/>
      <div className='flex flex-wrap pb-5 text-xs select-none'>
        { post.tags.map(tag => (
          <C.Link key={ tag.id } href={ h.path.style({ id: tag.id }) } preventDefault noUnderline>
            <div
              onClick={ () => h.path.go.style({ id: tag.id }) }
              className='cursor-pointer py-1 px-2 bg-gray-100 mr-[0.4rem] mb-[0.4rem] rounded-[4px] text-gray-500'
            >
            # { tag.name }
            </div>
          </C.Link>
        )) }
      </div>

      { !!post.stylist?.totalRatingCount && (
        <C.Link href={ h.path.stylist({ id: post.user.id }) } preventDefault noUnderline>
          <MoreBlock onClick={ () => h.path.go.stylist({ id: post.user.id }) }>
            <div className='font-bold line-clamp-1'>
              { t('panel:post.about') } { post.user.name }
            </div>
            <div className='flex items-center'>
              <span className='-translate-y-0.5'>
                <StarRateIcon fontSize='inherit' />
              </span>
              <span className='text-xs'>
                { post.stylist.rating.toFixed(1) } ({ t('panel:post.ratingCount', { count: post.stylist.totalRatingCount.format() }) })
              </span>
            </div>
            <div className='pt-[0.1rem] text-xs text-gray-400'>{ t('panel:post.stylistMore') }</div>
          </MoreBlock>
          <Hr/>
        </C.Link>
      ) }
      { !!post.stylist?.studio && (
        <C.Link href={ h.path.studio({ id: post.stylist.studio.id }) } preventDefault noUnderline>
          <MoreBlock onClick={ () => h.path.go.studio({ id: post.stylist.studio.id }) }>
            <div className='font-bold line-clamp-1'>{ t('panel:post.studio') } { post.stylist.studio.name }</div>
            <div className='pt-[0.1rem] text-xs'>{ post.stylist.studio.address }</div>
            <div className='pt-[0.1rem] text-xs text-gray-400'>{ t('panel:post.studioMore') }</div>
          </MoreBlock>
          <Hr />
        </C.Link>
      ) }
    </div>
  )
}

const MoreBlock = ({ children, onClick }) => (
  <div onClick={ onClick } className='py-3 text-sm flex items-center justify-between hover:bg-gray-100 select-none'>
    <div>{ children }</div>
    <Icon.ChevronRight className='scale-[80%]'/>
  </div>
)

const Hr = () => <div className='border-b'></div>
