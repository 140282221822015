export default function Photos(parent) {
  const { state, mutate } = useStatic({ index: 0 })
  const { isAdmin } = useCurrent()

  const currentSrc = parent.state.photos[state.index]
  const paddingTop = () => {
    const { width, height } = parent.state
    if (!width) return 100 + '%'
    return height / width * 100 + '%'
  }

  const preStyle = () => {
    if (state.index > 0) return 'cursor-pointer'
    return 'opacity-0'
  }

  const nextStyle = () => {
    if (state.index < parent.state.photos.length - 1) return 'cursor-pointer'
    return 'opacity-0'
  }

  const setPicture = index => {
    if (index === state.index) return
    if (index < 0) return
    if (index >= parent.state.photos.length) return
    state.index = index
    mutate()
    mkt.photoClick()
  }

  const renderDonwloadBtn = () => {
    if (!isAdmin) return
    return (
      <div className='absolute right-10 top-1 p-1 text-white cursor-pointer bg-gray-500/25'
      >
        <C.Link href={ currentSrc } blank noColor>
          <Icon.Download />
        </C.Link>
      </div>
    )
  }

  const renderImagePlaceHolder = () => (
    <>
      <div
        className='hidden sm:block'
        style={ parent.photoSplitStyle }
      >
        <div className='relative w-full h-full overflow-hidden'>
          {renderImages()}
        </div>
      </div>
      <div
        className='w-full sm:hidden relative overflow-hidden'
        style={ { paddingTop: paddingTop() } }
      >
        {renderImages()}
      </div>
    </>
  )

  const renderBeforeTag = () => {
    if (!currentSrc.includes('before')) return
    return (
      <div className={ cn('absolute bottom-3 left-3 text-white bg-black bg-opacity-30 opacity-90 px-3 py-1 rounded-md text-[12px]') }>Before</div>
    )
  }

  const renderImages = () => (
    parent.state.photos.map((src, index) => (
      <div
        key={ src }
        className='absolute w-full h-full transition-all duration-300'
        style={
          {
            top: 0 + '%',
            left: (index - state.index) * 100 + '%'
          }
        }
      >
        <C.Image
          src={ src }
          className='w-full h-full'
          type='cover'
        />
      </div>
    ))
  )

  return (
    <div className='relative'>
      <div className='relative'>
        {renderImagePlaceHolder()}
        <C.FloatDot lists={ parent.state.photos } at={ state.index } />
      </div>
      {
        renderBeforeTag()
      }
      <div className='absolute left-0 top-0 bottom-0 right-1/2'
        onClick={ () => setPicture(state.index - 1) }
      />
      <div className='absolute left-1/2 top-0 bottom-0 right-0'
        onClick={ () => setPicture(state.index + 1) }
      />
      {renderDonwloadBtn()}
      <Btn
        Icon={ Icon.ChevronLeft }
        className={ cn('top-1/2 left-0 -translace-y-1/2', preStyle()) }
        onClick={ () => setPicture(state.index - 1) }
      />
      <Btn
        Icon={ Icon.ChevronRight }
        className={ cn('top-1/2 right-0 -translace-y-1/2', nextStyle()) }
        onClick={ () => setPicture(state.index + 1) }
      />
    </div>
  )
}

const Btn = ({ Icon, className, iconClassName, ...props }) => (
  <div
    className={ cn('absolute rounded-full p-2 bg-gray-500/5', className) }
    { ...props }
  >
    <Icon className={ cn('text-white scale-[140%]', iconClassName) } />
  </div>
)
