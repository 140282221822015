import postCollectCreate from 'request/common/post-collect-create'
import postCollectDelete from 'request/common/post-collect-delete'
import postDelete from 'request/common/post-delete'
import postBlackList from 'request/common/post-black-list'
import postCover from 'request/common/post-cover'
import useBlock from 'hooks/block'

export default function Buttons(parent) {
  const t = useI18n()
  const panel = usePanel()
  const { user, isAdmin } = useCurrent()
  const { state, mutate } = useStatic({
    liked: parent.state.post.isCollected,
    panelVisible: false
  })

  const { blockActionWrapper } = useBlock(parent.state.post.user.id)
  const color = () => {
    if (state.liked) return 'text-red-500'
    return 'text-gray-400'
  }
  const toggleLike = () => {
    if (!user) return panel.open('auth')
    state.liked = !state.liked
    postCollect(parent.state.post.id, state.liked, t)
    mutate()
  }
  const toggleOther = () => {
    state.panelVisible = !state.panelVisible
    mutate()
  }
  const bgStyle = () => {
    if (state.panelVisible) return ''
    return 'pointer-events-none'
  }
  return (
    <div className='pb-3 md:pb-2 flex justify-between text-gray-400 relative'>
      <div className='flex space-x-6 px-4'>
        <Btn onClick={ toggleLike }>
          <Icon.Heart filled className={ cn('scale-125', color()) } />
        </Btn>
        <Btn onClick={ () => blockActionWrapper(() => $messenger.talkToUser(parent.state.post.user.id)) }>
          <Icon.Chat className='scale-125'/>
        </Btn>
      </div>
      <div className='px-3'>
        <Btn onClick={ toggleOther }>
          <Icon.Dot className='scale-125'/>
        </Btn>
      </div>
      <div className={ cn('fixed inset-0', bgStyle()) }
        onClick={ toggleOther }
      />
      <Panel
        visible={ state.panelVisible }
        user={ user }
        isAdmin={ isAdmin }
        parent={ parent }
        toggle={ toggleOther }
        t={ t }
      />
    </div>
  )
}

const Btn = ({ children, ...props }) => (
  <div className='flex-1 flex justify-center items-center py-[6px] cursor-pointer relative'
    { ...props }
  >
    { children }
  </div>
)

const Panel = ({ visible, user, isAdmin, parent, toggle, t }) => {
  const { post } = parent.state

  const style = () => {
    if (visible) return 'opacity-100 scale-100'
    return 'opacity-0 scale-50 pointer-events-none'
  }

  const btnWraper = callback => () => {
    toggle()
    callback()
  }

  const shareClick = btnWraper(() => {
    const url = h.path.full.post({ id: post.id })
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`
    window.open(shareUrl, t('panel:post.shareToFB'), 'width=480, height=480')
  })
  const copyClick = btnWraper(() => {
    const url = h.path.full.post({ id: post.id })
    if (!navigator.clipboard) return fallbackCopyTextToClipboard(url, t)
    navigator.clipboard.writeText(url).then(function() {
      $alert(t('panel:post.linkCopied'))
    }, function(err) {
      $alert(`${t('panel:post.copyFail')} (${err})`, 'error')
    })
  })
  const reportClick = btnWraper(() => {
    h.contactUs({
      title: `${t('panel:post.reportPost')} (ID: ${post.id})`,
      message: ''
    })
  })

  const postCoverClick = () => {
    const isCancel = !!post.score
    postCover({ postId: post.id, isCancel })
    post.score = !isCancel
    parent.mutate()
    const successMsg = isCancel ? t('panel:post.cancelled') : t('panel:post.didSet')
    $alert(successMsg)
  }
  const refreshPost = post => {
    parent.state.post = post
    parent.reloadPost()
  }
  const editPostClick = btnWraper(() => {
    h.openPostCreate({
      post,
      user,
      callback: refreshPost
    })
  })
  const deletePostClick = btnWraper(async() => {
    if (!confirm(t('panel:post.confirmDelete'))) return
    await postDelete(post.id)
    $alert(t('panel:post.deleted'))
    parent.closePanel()
  })
  const blackListClick = btnWraper(async() => {
    if (!confirm(t('panel:post.confirmBlackList'))) return
    await postBlackList(post.id)
    $alert(t('panel:post.added'))
  })
  const copyIdClick = btnWraper(async() => {
    if (!navigator.clipboard) return fallbackCopyTextToClipboard(post.id, t)
    navigator.clipboard.writeText(post.id).then(function() {
      $alert(t('panel:post.linkCopied'))
    }, function(err) {
      $alert(`${t('panel:post.copyFail')} (${err})`, 'error')
    })
  })

  const renderAdminBtns = () => {
    if (!isAdmin) return
    return (
      <>
        <PanelBtn onClick={ blackListClick }>{ t('panel:post.blackList') }</PanelBtn>
        <PanelBtn onClick={ copyIdClick }>{ t('panel:post.copyId') }</PanelBtn>
      </>
    )
  }

  const renderSpecialBtns = () => {
    if (!isAdmin && user?.id !== post.user.id) return
    const postCoverTitle = post.score ? t('panel:post.cancelCover') : t('panel:post.setAsCover')
    return (<>
      <PanelBtn onClick={ postCoverClick }>{ postCoverTitle }</PanelBtn>
      <PanelBtn onClick={ editPostClick }>{ t('panel:post.edit') }</PanelBtn>
      <PanelBtn onClick={ deletePostClick }>{ t('panel:post.delete') }</PanelBtn>
      { renderAdminBtns() }
    </>)
  }

  return (
    <div className={ cn('absolute bottom-[100%] left-0 bg-white py-2 opacity-95 shadow w-full text-center border text-gray-600 rounded-[5px] transition-all', style()) }>
      <PanelBtn onClick={ shareClick }>{ t('panel:post.share') }</PanelBtn>
      <PanelBtn onClick={ copyClick }>{ t('panel:post.copyLink') }</PanelBtn>
      <PanelBtn onClick={ reportClick }>{ t('panel:post.reportPost') }</PanelBtn>
      { renderSpecialBtns() }
    </div>
  )
}

const PanelBtn = ({ children, onClick }) => (
  <div className='py-2 cursor-pointer hover:bg-gray-200 font-medium'
    onClick={ onClick }
  >
    { children }
  </div>
)

const postCollect = (postId, liked, t) => {
  if (!liked) return postCollectDelete(postId)
  $alert(t('common:collected'))
  mkt.postCollect(postId)
  return postCollectCreate(postId)
}

const fallbackCopyTextToClipboard = (text, t) => {
  var textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    var successful = document.execCommand('copy')
    if (successful) {
      $alert(t('panel:post.linkCopied'))
    } else {
      $alert(t('panel:post.copyFail'), 'error')
    }
  } catch (err) {
    $alert(`${t('panel:post.copyFail')} (${err})`, 'error')
  }

  document.body.removeChild(textArea)
}
